import React from 'react';
import { FaSearchLocation } from 'react-icons/fa';
import { BsTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { FaTelegram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className="">
      <div className="bg-[#6b195a]">
        <div className='p-6 lg:flex sm:grid  justify-between align-middle gap-5'>
          <div className="flex-1 grid">
            <p className='flex justify-center text-center text-[#F56A01] text-xl font-bold'>About Radha Krishna Gram Vikash Kendra</p>
            <span className='text-white mt-4'>Radha Krishna Gram Vikas Kendra is a Society who is working for Education,Culture & Teachings of Srimad Bhagavad Gita under the guidance of Bramha-Madhva-Gaudiya Sampradaya (ISKCON)</span>
          </div>

          <div className="flex-1 grid ">
            <p className='flex justify-center text-center text-[#F56A01] text-xl font-bold'>Quick Links</p>
            <div className="flex justify-center gap-10 m-6">
              <div className="flex gap-6">
                <Link className='text-white' to={'https://chat.whatsapp.com/EAob3SvLrMRB9VYm48iPtf'}  target="_blank"><FaWhatsapp className='text-4xl text-[#F56A01]' /></Link>
              </div>
              <div className="flex gap-6">
                <Link className='text-white' to={'https://t.me/+iaFiUK6gqso1YzU1'} target="_blank"><FaTelegram className='text-4xl text-[#F56A01]' /></Link>
              </div>
              <div className="flex gap-6">
                <Link className='text-white' to={'https://www.youtube.com/@ssgdnhk'} target="_blank"><FaYoutube className='text-4xl text-[#F56A01]' /></Link>
              </div>

            </div>
          </div>

          <div className="flex-1 grid">
            <p className='flex justify-center text-center text-[#F56A01] text-xl font-bold'>CONTACT US</p>
            <div className="grid">
              <div className="flex gap-6">
                <FaSearchLocation className='text-4xl text-[#F56A01] ' />
                <p className='text-white'>Jaipalpur ( Chaka ), Mungra Badshahpur, Jaunpur U.P PIN Code - 222202 </p>
              </div>
              <div className="flex gap-6">
                <BsTelephoneFill className='text-2xl text-[#F56A01] ' />
                <p className='text-white'>+91-7042109297, +91-7525858518</p>
              </div>
              <div className="flex gap-6">
                <MdEmail className='text-2xl text-[#F56A01] ' />
                <p ><Link className='text-white cursor-pointer' to="mailto:radhakrishnagramvikaskendra@gmail.com">radhakrishnagramvikaskendra@gmail.com</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='p-6 bg-[#F56A01]'>
        <p className='text-white'>Copyright © 2024 Radha Krishna Gram Vikash Kendra . All Rights Reserved.</p>
      </div>
    </div>

  )
}

export default Footer;