import React from 'react'
import Layout from './Layout/Layout';
import IMG1 from '../assets/ChakaBace.jpg';
import img2 from '../assets/prabhupada.jpeg';
import IMGM from '../assets/img2.jpg';
import IMGC from '../assets/img10.jpg';
import IMGSB from "../assets/img7.jpg";
import GuruMaharaj from "../assets/Guru Maharaj.jpg"
import Program from './Program';


const About = () => {
  return (
    <Layout>

      <div className="">
        <div className='flex justify-center align-middle text-center text-2xl font-bold rounded-3xl bg-[#6b195a] m-10'>
          <p className='p-4  rounded-lg cursor-pointer text-white'>About Us</p>
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-6 p-4 lg:p-10">
          <div className="overflow-hidden rounded-md max-w-full lg:max-w-[30vw] lg:max-h-[90vh] sm:max-w-[50vw] sm:max-h-[80vh]">
            <img src={IMG1} alt="Responsive Image" className="hover:scale-110 transition duration-500 cursor-pointer w-full h-auto object-cover" />
          </div>

          <div className="flex-1">
            <p className="text-xl sm:text-2xl lg:text-3xl flex justify-center lg:justify-center">Welcome</p>

            <div className="grid gap-6 pt-4 sm:pt-7">
              <p className="p-2 text-sm sm:text-base lg:text-lg">
                <span className="font-bold">Radha Krishna Gram Vikas Kendra</span> is a non-profit initiative of Alumni and Working Professionals from Prestigious Colleges like IIT, NIET & MBBS. <br />
                <span className="font-bold">Radha Krishna Gram Vikas Kendra</span> is moving towards highlighting the Culture, Values, as well as Krishna Consciousness among Children and their Parents so that People in the Society can live with each other with Love and Happiness.
              </p>
            </div>
          </div>
        </div>


        <div className="grid justify-center align-middle gap-7">
          <div className='flex justify-center align-middle text-center text-2xl font-bold rounded-3xl bg-[#6b195a] m-10'>
            <p className='p-4  rounded-lg cursor-pointer text-white'>About Srila Prabhupada</p>
          </div>
          <div className='flex justify-center align-middle'>
            <img src={img2} alt="" className='rounded-md max-w-[90vw] ' />
          </div>
          <div className='p-8'>
            <p className='shadow-lg text-white font-bold bg-gradient-to-r from-[#ef745c] to-[#34073d] p-6 rounded-lg'>
              His Divine Grace A.C. Bhaktivedanta Swami Prabhupada appeared in this world in 1896 in Calcutta, India. He first met his spiritual master, Srila Bhaktisiddhanta Sarasvati Gosvami, in Calcutta in 1922. Bhaktisiddhanta Sarasvati, a prominent religious scholar and the founder of 64 Gaudiya Mathas (Vedic Institutes), liked this educated young man and convinced him to dedicated his life to teaching Vedic knowledge. Srila Prabhupada became his student, and eleven years later (1933) at Allahabad he became his formally initated disciple.

              At their first meeting, in 1922, Srila Bhaktisiddhanta Sarasvati Thakura requested Srila Prabhupada to broadcast Vedic knowledge through the English language. In the years that followed, Srila Prabhupada wrote a commentary on the Bhagavad-gita, assisted the Gaudiya Matha in its work and, in 1944, without assistance, strarted Back to Godhead, an English fortnightly magazine, edited it, typed the manuscripts and checked the gally proofs. He even distributed the individual copies and struggled to maintain the publication. Once begun, the magazine never stopped; it is now being continued by his disciples in the West and is published in over thirty languages.

              Recognizing Srila Prabhupada's philosophical learning and devotion, the Gaudiya Vaisnava Society honored him in 1947 with the title "Bhaktivedanta." In 1950, at the age of fifty-four, Srila Prabupada retired from married life, adopting the vanaprastha (retired) order to devote more time to his studies and writing. Srila Prabhupada traveled to the holy city of Vrndavana, where he lived in very humble circumstances in the historic medieval temple of Radha-Damodara. There he engaged for several years in deep studying and writing. He accepted the renounced order of life (sannyasa) in 1959. At Radha-Damodara,

              In 1965, he crossed the ocean with great difficulty arriving at Boston Harbor. Later he want to New York City, where after almost a year of great difficulty he established the International Society for Krishna Consciousness in July of 1966.

              In 1968, Srila Prabhupada created New Vrindavana, an experimental Vedic community in the hills of West Virginia. Inspired by the success of New Vrindavana, now a thriving farm community of more than one thousand acres, his students have since founded several similar communities in the United States and abroad.

              In 1972, His Divine Grace established the Vedic system of primary and secondary education in the West by founding the Gurukula school in Dallas Texas. Since then, under his supervision, his disciples have established children's schools throughout the United States and the rest of the world.

              Srila Prabhupada also inspired the construction of several large international cultural centers in India. The center in Sridhama Mayapur, inWest Bengal is the site for a planned spiritual city, an ambious project for which construction will extend over the next decades. In Vrindavana India is the magnificent Krishna Balarama Temple and International Guesthouse. There is also a major cultural and educational center in Bombay.

              Srila Prabhupada's most significant contribution, however, is his books. Highly respected by the academic community for their authoritativeness, depth and clarity, they are used as standard textbooks in numerous college courses. His writings have been translated into more than thrity languages. The Bhaktivedanta Book Trust, established in 1972 exclusively to publish the works of His Divine Grace, has thus become the worlds largest publisher of books in the field of Indian religion and philosophy.

              In just twelve years, in spite of his advance age, Srila Prabhupada circled the globe fourteen times on lecture tours that took him to six continents. In spite of such a vigorous schedule, Srila Prabhupada continued to write prolifically. His writings constitute a veritable library of Vedic philosophy, religion, literature and culture.

              Before his passing away on November 14, 1977, he had guided the society and saw it grow to a worldwide confederation of more than one hundred
            </p>
          </div>
        </div>

        <div className="grid justify-center align-middle gap-7">
          <div className='flex justify-center align-middle text-center text-2xl font-bold rounded-3xl bg-[#6b195a] m-10'>
            <p className='p-4  rounded-lg cursor-pointer text-white'>About Srila Bhagavadpad</p>
          </div>
          <div className='flex justify-center items-center'>
            <img
              src={GuruMaharaj}
              alt=""
              className='rounded-md w-full max-w-[90%] md:max-w-[70%] lg:max-w-[50%] h-auto'
            />
          </div>

          <div className='p-8'>
            <p className='shadow-lg text-white font-bold bg-gradient-to-r from-[#ef745c] to-[#34073d] p-6 rounded-lg'>
              Born on the auspicious day of Annada Ekadashi, August 14, 1944, in New Delhi, Maharaj’s original name, Gopal Krishna, was retained by Srila Prabhupada after his Harinama initiation.

              Maharaj spent his early years in India, graduating from the University of Delhi. He then pursued further studies abroad, securing a scholarship from the French Government to study Business Management at Sorbonne University in Paris. Later, he earned a Master’s Degree in Business Administration from McGill University in Montreal.

              Maharaj was always a keen student of spirituality. In his youth, he explored various paths, visited churches, and gurudwaras, and listened to many spiritual discourses in India as well as in the West. Being a sincere student of spirituality, maharaja quickly grasped the unique guru-shishya relationship when he met Srila Prabhupada for the first time.
              <br />
              <b>Meeting With Srila Parbhupad</b>
              <br />
              When he met His Divine Grace A.C. Bhaktivedanta Swami Srila Prabhupada, the founder-Acharya of ISKCON, at an ISKCON temple in Montreal. In a humble yet significant beginning, he cleaned Srila Prabhupada’s apartment as his first service. He soon accepted Srila Prabhupada as his spiritual master, becoming a surrendered soul at the lotus feet of a pure devotee. The relationship between him and Srila Prabhupada deepened over time as Srila Prabhupada guided him through letters to grow on his spiritual journey.

              In 1975, Srila Prabhupada appointed Gopal Krsna Goswami Maharaj as the General Body Commissioner (GBC) of India and entrusted him with critical projects to expand book distribution in the country. Srila Prabhupada encouraged Maharaj to utilize his marketing knowledge in the service of Lord Krishna. With unwavering determination and firm faith in Guru Maharaj’s words, Maharaj made India a leading hub for book distribution.

              Gopal Krsna Goswami Maharaj was also entrusted with the pivotal task of preaching in the communist Soviet Union, where he risked his safety to spread the message of Krishna and distribute books in difficult times.
            </p>
          </div>
        </div>

        <Program/>
      </div>
    </Layout>
  )
}

export default About;