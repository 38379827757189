import React, { useEffect, useState } from 'react';
import Layout from './Layout/Layout';
import IMGHERO from '../assets/Home3.jpg';
import Donation from './Donation';
import Program from './Program';

const Home = () => {
    // const [profilePic, setProfilePic] = useState(null);

    return (
        <Layout>
            <div className="grid ">
                <div className="flex justify-center align-middle ">
                    <img src={IMGHERO} alt="Photo hai " className='w-[100%] h-[100%]' />
                </div>

                <div className="flex flex-col justify-center align-middle pt-14 p-4">

                    <div className='flex justify-center align-middle text-center text-2xl font-bold rounded-3xl bg-[#6b195a] m-10'>
                        <p className='p-4  rounded-lg cursor-pointer text-white'>RKGVK Program Schedule</p>
                    </div>
                    <div className="border-1  border-gray-700 min-w-[50vw] min-h-[25vh] flex flex-col justify-center align-middle mx-auto rounded-xl shadow-md hover:scale-110 transition duration-500 cursor-pointer p-4">
                        <div className="flex flex-col justify-center align-middle items-center gap-3">
                            <div className="flex flex-col justify-center align-middle items-center gap-3">
                                {/* <span className='text-xl font-bold'>RKGVK Program Schedule</span> */}
                                <div className="flex flex-col justify-center text-center gap-1">
                                    <p>Mangal Aroti
                                        (4:30 am to 5:15 am)</p>
                                    <p>Gaura Aroti
                                        (8 pm to 8:30 pm)</p>
                                    <p>Every Monday BG Class
                                        (8 pm to 9 pm)</p>
                                    <p>Regular Free Coaching Classes from Class 1st to 8th ( 3:00 pm to 5:00 pm )</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Program />
            </div>
        </Layout>
    )
}

export default Home