import React from 'react'
import IMG1 from '../assets/baceweb.jpg';
import IMGM from '../assets/img2.jpg';
import IMGC from '../assets/img10.jpg';
import IMGSB from "../assets/img7.jpg";
import GaurPurnima from "../assets/Gaurpurnima.jpg";
import punyatithi from "../assets/punyatithi.jpg";
import srikrishnajanmastami from "../assets/janmastami.jpg";
import FreeClasses from '../assets/Activity1.jpg';
const Program = () => {
    return (
        <div className="pt-16 pb-16">
            <div className='flex justify-center align-middle text-center text-2xl font-bold rounded-3xl bg-[#6b195a] m-10'>
                <p className='p-4 rounded-lg cursor-pointer text-white'>RKGVK Programs</p>
            </div>

            <div className="flex justify-center align-middle pt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                    {[
                        { img: IMG1, title: "Mangla Aarti" },
                        { img: IMGM, title: "Gaura Aarti" },
                        { img: IMGSB, title: "Sunday BG Class" },
                        { img: IMGC, title: "Village SB Class" },
                        { img: GaurPurnima, title: "Gaura Purnima Program" },
                        { img: punyatithi, title: "Mataji Punyatithi Program" },
                        { img: srikrishnajanmastami, title: "Shri Krishna Janmashtami" },
                        { img: FreeClasses, title: "Free Coaching Classes" }
                    ].map((item, index) => (
                        <div key={index} className="flex flex-col justify-center align-middle text-center gap-4">
                            <div className="overflow-hidden rounded-md">
                                <img src={item.img} alt={`Image of ${item.title}`} className='w-60 h-60 border-1 border-orange-500  shadow-lg cursor-pointer hover:scale-110 transition duration-500 cursor-pointer' />
                            </div>
                            <span className='p-3 border border-orange-500 rounded-md shadow-lg cursor-pointer bg-[#66018A] font-bold text-white'>
                                {item.title}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Program
