import IMG1 from '../assets/iskcon.jpg';
import IMG2 from '../assets/iskcon.jpg';
import IMG3 from '../assets/img1.jpg';
import IMG4 from '../assets/img2.jpg';
import IMG5 from '../assets/img3.jpg';
import IMG6 from '../assets/img4.jpg';
import IMG7 from '../assets/img5.jpg';
import IMG8 from '../assets/img6.jpg';
import IMG9 from '../assets/img7.jpg';
import IMG10 from '../assets/img8.jpg';
import IMG11 from '../assets/img9.jpg';
import IMG12 from '../assets/img10.jpg';
import IMG13 from '../assets/img11.jpg';
import IMG14 from '../assets/img12.jpg';
import IMG15 from '../assets/img13.jpg';

const data = [
    {
        IMG:IMG1
    },
    {
        IMG:IMG2
    },
    {
        IMG:IMG3
    },
    {
        IMG:IMG4
    },
    {
        IMG:IMG5
    },
    {
        IMG:IMG6
    },
    {
        IMG:IMG7
    },
    {
        IMG:IMG8
    },
    {
        IMG:IMG9
    },
    {
        IMG:IMG10
    },
    {
        IMG:IMG11
    },
    {
        IMG:IMG12
    },
    {
        IMG:IMG13
    },
    {
        IMG:IMG14
    },
    {
        IMG:IMG15
    },
]

export default data;