import React from 'react'
import Layout from "../components/Layout/Layout";
const DonationPage = () => {
  return (
    <Layout>
      <h1 className='text-center'>this page is under maintanance... will update it soon....</h1>
    </Layout>
  )
}

export default DonationPage;
