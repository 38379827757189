import React from 'react'
import Layout from './Layout/Layout';
import IMG1 from '../assets/Activity1.jpg';
import Donation from './Donation';
const Activity = () => {
    return (
        <Layout>
            <div className=" text-center p-10">
                <span className='flex justify-center align-middle text-2xl bg-[#6b195a] p-4 rounded-xl text-white shadow-lg cursor-auto'>Activities </span>

                <div className="">
                    <section class="text-gray-600 body-font">
                        <div class="container px-5 py-12 mx-auto">
                            <div class="flex flex-wrap m-4">
                                <div class="p-4 md:w-1/3">
                                    <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                        <img class="lg:h-72 md:h-36 w-full object-cover object-center" src={IMG1} alt="blog" />
                                        <div class="p-4">
                                            <h1 class="title-font text-lg text-gray-900 font-bold m-5">Free Computer Classes</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-4 md:w-1/3">
                                    <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                        <img class="lg:h-72 md:h-36 w-full object-cover object-center" src={IMG1} alt="blog" />
                                        <div class="p-4">
                                            <h1 class="title-font text-lg font-bold text-gray-900 m-5">Free English Speaking Course</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-4 md:w-1/3">
                                    <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                        <img class="lg:h-72 md:h-36 w-full object-cover object-center" src={IMG1} alt="blog" />
                                        <div class="p-4">
                                            <h1 class="title-font text-lg font-bold text-gray-900 m-5">Free Bhagavad Gita Classes</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-4 md:w-1/3">
                                    <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                        <img class="lg:h-72 md:h-36 w-full object-cover object-center" src={IMG1} alt="blog" />
                                        <div class="p-4">
                                            <h1 class="title-font text-lg font-bold text-gray-900 m-5">Free All Subject ( Math, Science, Social Science, English ) Classes </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Donation />
        </Layout>
    )
}

export default Activity