import React, { useState } from 'react'
import Layout from './Layout/Layout'
import RESUME from "../assets/Abhay_patel_Resume.pdf";
import Atmsat from "../assets/आत्म साक्षात्कार का विज्ञान.pdf";
import KRISHNA from "../assets/कृष्ण की ओर On the way to Krishna.pdf";
import KRISHNAKADAM from "../assets/कृष्ण भवना मृत में पहला कदम.pdf";
import PUNARJANAM from "../assets/पुनरागमन.pdf";
const Ebooks = () => {

  const [pdfList, setPdfList] = useState([
    {
      name: "आत्म साक्षात्कार का विज्ञान",
      url: Atmsat,
    },
    {
      name:"कृष्ण की ओर On the way to Krishna",
      url:KRISHNA
    }, {
      name:"कृष्ण भवना मृत में पहला कदम",
      url:KRISHNAKADAM
    },
    {
      name: "पुनरागमन",
      url:PUNARJANAM
    }
  ]);

  const handleDownload = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleView = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Layout>

      <div className="container mx-auto p-4">
      <div className='flex justify-center align-middle text-center text-2xl font-bold rounded-3xl bg-[#6b195a] m-10'>
          <p className='p-4  rounded-lg cursor-pointer text-white'>Srila Prabhupada E-Books</p>
        </div>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border">Document Name</th>
              <th className="py-2 px-4 border">View</th>
              <th className="py-2 px-4 border">Download</th>
            </tr>
          </thead>
          <tbody>
            {pdfList.map((pdf, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border">{pdf.name}</td>
                <td className="py-2 px-4 border text-center">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleView(pdf.url)}
                  >
                    View
                  </button>
                </td>
                <td className="py-2 px-4 border text-center">
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleDownload(pdf.url, pdf.name)}
                  >
                    Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </Layout>
  )
}

export default Ebooks;
